import { default as indexHKX70xlV1YMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/cases-de-sucesso/index.vue?macro=true";
import { default as conta_45bancarialQ1XfzDT6wMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue?macro=true";
import { default as dados_45documentowpiA81mz6JMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue?macro=true";
import { default as dados_45patrimoniais76t7m0L0ayMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue?macro=true";
import { default as declaracoeshL0JVWa2EeMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue?macro=true";
import { default as documentosLWi9IHh6b7Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/documentos.vue?macro=true";
import { default as enderecoALRhpZ9nrzMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/endereco.vue?macro=true";
import { default as indexOmE8UObEwEMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/index.vue?macro=true";
import { default as informacoes_45pessoaisRmAheqO2WPMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue?macro=true";
import { default as _91partner_93iYq5MDDtmQMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue?macro=true";
import { default as index4je2rwvkCXMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/index.vue?macro=true";
import { default as confirmacaouaT4OlWEo9Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue?macro=true";
import { default as detalhesuMqp2CnNeeMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue?macro=true";
import { default as enquadramentoNEx2Ajlt5hMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue?macro=true";
import { default as index58KWxToma0Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/index.vue?macro=true";
import { default as confirmar_45contawEcKNhVA7UMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue?macro=true";
import { default as criar_45contalPrGvQdVwiMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue?macro=true";
import { default as indexHBKaLHjMjUMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue?macro=true";
import { default as inicioITPwQVo3xfMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue?macro=true";
import { default as _91indicationCode_93HvI82yu0Z8Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue?macro=true";
import { default as _91operationId_93Ww9zWxO4ZiMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/[operationId].vue?macro=true";
import { default as finalizadasyscyKGy6UOMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/finalizadas.vue?macro=true";
import { default as historicoBzLRAlvsBgMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/historico.vue?macro=true";
import { default as indexkxsb0ERfIrMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/index.vue?macro=true";
import { default as _91operationId_93w0bcFnFRvYMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue?macro=true";
import { default as indexTJrkukB0UMMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/originadoras/index.vue?macro=true";
import { default as _91slug_93UYPA494WoEMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ajuda/[slug].vue?macro=true";
import { default as indexaxJKh8k0h1Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ajuda/index.vue?macro=true";
import { default as _91operationId_93NYZ76FFScpMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue?macro=true";
import { default as indexwG24MzITmDMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/index.vue?macro=true";
import { default as indexPmZ8MfCIT9Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/indicacao/index.vue?macro=true";
import { default as indexHiYDF3oQcKMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ir/index.vue?macro=true";
import { default as indexvgxcta8fH7Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/pendentes/index.vue?macro=true";
import { default as indexo35pKnePJFMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue?macro=true";
import { default as index6d1ARlGWpTMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue?macro=true";
import { default as index6KR0zMHKFUMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue?macro=true";
import { default as adicionarvuqEZTOYcpMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue?macro=true";
import { default as indexDExkQu6gKNMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue?macro=true";
import { default as editary4cFNaq8BPMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue?macro=true";
import { default as adicionar0EzNHZx3JzMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue?macro=true";
import { default as indexfBQdVfRgxZMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue?macro=true";
import { default as indexyHrkI7syTVMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue?macro=true";
import { default as indexZgun2Ylq73Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue?macro=true";
import { default as indexrI9qtRF54OMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue?macro=true";
import { default as indexNB8F5FGg3XMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue?macro=true";
import { default as index9Jy1RCCmQbMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue?macro=true";
import { default as indexbsNgSgvobvMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/index.vue?macro=true";
import { default as _91assetSymbol_93h7l7Ns3Bf5Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue?macro=true";
import { default as depositoG66rWAGP4CMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/deposito.vue?macro=true";
import { default as indexHEf03dAg19Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/index.vue?macro=true";
import { default as resgatehJuPsTtTnDMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/resgate.vue?macro=true";
import { default as indexwtijAw0oPSMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/parceiros/index.vue?macro=true";
import { default as indexkhBQUNKyu2Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/politica-de-privacidade/index.vue?macro=true";
import { default as indexqHRpSNiFfxMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/quem-somos/index.vue?macro=true";
import { default as indexqngxVKVTYOMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/remocao-de-conta/index.vue?macro=true";
import { default as _91signupCode_93KNbMIqXrx4Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue?macro=true";
import { default as indexrMDGhZIs5sMeta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/termos-de-uso/index.vue?macro=true";
import { default as indexIwOnaoMM35Meta } from "/codebuild/output/src1798402820/src/hcp-web-platform/pages/tokenizacao/index.vue?macro=true";
export default [
  {
    name: indexHKX70xlV1YMeta?.name ?? "cases-de-sucesso",
    path: indexHKX70xlV1YMeta?.path ?? "/cases-de-sucesso",
    meta: indexHKX70xlV1YMeta || {},
    alias: indexHKX70xlV1YMeta?.alias || [],
    redirect: indexHKX70xlV1YMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/cases-de-sucesso/index.vue").then(m => m.default || m)
  },
  {
    name: conta_45bancarialQ1XfzDT6wMeta?.name ?? "completar-cadastro-conta-bancaria",
    path: conta_45bancarialQ1XfzDT6wMeta?.path ?? "/completar-cadastro/conta-bancaria",
    meta: conta_45bancarialQ1XfzDT6wMeta || {},
    alias: conta_45bancarialQ1XfzDT6wMeta?.alias || [],
    redirect: conta_45bancarialQ1XfzDT6wMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/conta-bancaria.vue").then(m => m.default || m)
  },
  {
    name: dados_45documentowpiA81mz6JMeta?.name ?? "completar-cadastro-dados-documento",
    path: dados_45documentowpiA81mz6JMeta?.path ?? "/completar-cadastro/dados-documento",
    meta: dados_45documentowpiA81mz6JMeta || {},
    alias: dados_45documentowpiA81mz6JMeta?.alias || [],
    redirect: dados_45documentowpiA81mz6JMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/dados-documento.vue").then(m => m.default || m)
  },
  {
    name: dados_45patrimoniais76t7m0L0ayMeta?.name ?? "completar-cadastro-dados-patrimoniais",
    path: dados_45patrimoniais76t7m0L0ayMeta?.path ?? "/completar-cadastro/dados-patrimoniais",
    meta: dados_45patrimoniais76t7m0L0ayMeta || {},
    alias: dados_45patrimoniais76t7m0L0ayMeta?.alias || [],
    redirect: dados_45patrimoniais76t7m0L0ayMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/dados-patrimoniais.vue").then(m => m.default || m)
  },
  {
    name: declaracoeshL0JVWa2EeMeta?.name ?? "completar-cadastro-declaracoes",
    path: declaracoeshL0JVWa2EeMeta?.path ?? "/completar-cadastro/declaracoes",
    meta: declaracoeshL0JVWa2EeMeta || {},
    alias: declaracoeshL0JVWa2EeMeta?.alias || [],
    redirect: declaracoeshL0JVWa2EeMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/declaracoes.vue").then(m => m.default || m)
  },
  {
    name: documentosLWi9IHh6b7Meta?.name ?? "completar-cadastro-documentos",
    path: documentosLWi9IHh6b7Meta?.path ?? "/completar-cadastro/documentos",
    meta: documentosLWi9IHh6b7Meta || {},
    alias: documentosLWi9IHh6b7Meta?.alias || [],
    redirect: documentosLWi9IHh6b7Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/documentos.vue").then(m => m.default || m)
  },
  {
    name: enderecoALRhpZ9nrzMeta?.name ?? "completar-cadastro-endereco",
    path: enderecoALRhpZ9nrzMeta?.path ?? "/completar-cadastro/endereco",
    meta: enderecoALRhpZ9nrzMeta || {},
    alias: enderecoALRhpZ9nrzMeta?.alias || [],
    redirect: enderecoALRhpZ9nrzMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/endereco.vue").then(m => m.default || m)
  },
  {
    name: indexOmE8UObEwEMeta?.name ?? "completar-cadastro",
    path: indexOmE8UObEwEMeta?.path ?? "/completar-cadastro",
    meta: indexOmE8UObEwEMeta || {},
    alias: indexOmE8UObEwEMeta?.alias || [],
    redirect: indexOmE8UObEwEMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/index.vue").then(m => m.default || m)
  },
  {
    name: informacoes_45pessoaisRmAheqO2WPMeta?.name ?? "completar-cadastro-informacoes-pessoais",
    path: informacoes_45pessoaisRmAheqO2WPMeta?.path ?? "/completar-cadastro/informacoes-pessoais",
    meta: informacoes_45pessoaisRmAheqO2WPMeta || {},
    alias: informacoes_45pessoaisRmAheqO2WPMeta?.alias || [],
    redirect: informacoes_45pessoaisRmAheqO2WPMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/completar-cadastro/informacoes-pessoais.vue").then(m => m.default || m)
  },
  {
    name: _91partner_93iYq5MDDtmQMeta?.name ?? "crie-sua-conta-partner",
    path: _91partner_93iYq5MDDtmQMeta?.path ?? "/crie-sua-conta/:partner()",
    meta: _91partner_93iYq5MDDtmQMeta || {},
    alias: _91partner_93iYq5MDDtmQMeta?.alias || [],
    redirect: _91partner_93iYq5MDDtmQMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/crie-sua-conta/[partner].vue").then(m => m.default || m)
  },
  {
    name: index4je2rwvkCXMeta?.name ?? "index",
    path: index4je2rwvkCXMeta?.path ?? "/",
    meta: index4je2rwvkCXMeta || {},
    alias: index4je2rwvkCXMeta?.alias || [],
    redirect: index4je2rwvkCXMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmacaouaT4OlWEo9Meta?.name ?? "investir-operationId-confirmacao",
    path: confirmacaouaT4OlWEo9Meta?.path ?? "/investir/:operationId()/confirmacao",
    meta: confirmacaouaT4OlWEo9Meta || {},
    alias: confirmacaouaT4OlWEo9Meta?.alias || [],
    redirect: confirmacaouaT4OlWEo9Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/confirmacao.vue").then(m => m.default || m)
  },
  {
    name: detalhesuMqp2CnNeeMeta?.name ?? "investir-operationId-detalhes",
    path: detalhesuMqp2CnNeeMeta?.path ?? "/investir/:operationId()/detalhes",
    meta: detalhesuMqp2CnNeeMeta || {},
    alias: detalhesuMqp2CnNeeMeta?.alias || [],
    redirect: detalhesuMqp2CnNeeMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/detalhes.vue").then(m => m.default || m)
  },
  {
    name: enquadramentoNEx2Ajlt5hMeta?.name ?? "investir-operationId-enquadramento",
    path: enquadramentoNEx2Ajlt5hMeta?.path ?? "/investir/:operationId()/enquadramento",
    meta: enquadramentoNEx2Ajlt5hMeta || {},
    alias: enquadramentoNEx2Ajlt5hMeta?.alias || [],
    redirect: enquadramentoNEx2Ajlt5hMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/enquadramento.vue").then(m => m.default || m)
  },
  {
    name: index58KWxToma0Meta?.name ?? "investir-operationId",
    path: index58KWxToma0Meta?.path ?? "/investir/:operationId()",
    meta: index58KWxToma0Meta || {},
    alias: index58KWxToma0Meta?.alias || [],
    redirect: index58KWxToma0Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/index.vue").then(m => m.default || m)
  },
  {
    name: inicioITPwQVo3xfMeta?.name ?? undefined,
    path: inicioITPwQVo3xfMeta?.path ?? "/investir/:operationId()/inicio",
    meta: inicioITPwQVo3xfMeta || {},
    alias: inicioITPwQVo3xfMeta?.alias || [],
    redirect: inicioITPwQVo3xfMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio.vue").then(m => m.default || m),
    children: [
  {
    name: confirmar_45contawEcKNhVA7UMeta?.name ?? "investir-operationId-inicio-confirmar-conta",
    path: confirmar_45contawEcKNhVA7UMeta?.path ?? "confirmar-conta",
    meta: confirmar_45contawEcKNhVA7UMeta || {},
    alias: confirmar_45contawEcKNhVA7UMeta?.alias || [],
    redirect: confirmar_45contawEcKNhVA7UMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/confirmar-conta.vue").then(m => m.default || m)
  },
  {
    name: criar_45contalPrGvQdVwiMeta?.name ?? "investir-operationId-inicio-criar-conta",
    path: criar_45contalPrGvQdVwiMeta?.path ?? "criar-conta",
    meta: criar_45contalPrGvQdVwiMeta || {},
    alias: criar_45contalPrGvQdVwiMeta?.alias || [],
    redirect: criar_45contalPrGvQdVwiMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: indexHBKaLHjMjUMeta?.name ?? "investir-operationId-inicio",
    path: indexHBKaLHjMjUMeta?.path ?? "",
    meta: indexHBKaLHjMjUMeta || {},
    alias: indexHBKaLHjMjUMeta?.alias || [],
    redirect: indexHBKaLHjMjUMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/investir/[operationId]/inicio/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91indicationCode_93HvI82yu0Z8Meta?.name ?? "novo-investidor-indicationCode",
    path: _91indicationCode_93HvI82yu0Z8Meta?.path ?? "/novo-investidor/:indicationCode()",
    meta: _91indicationCode_93HvI82yu0Z8Meta || {},
    alias: _91indicationCode_93HvI82yu0Z8Meta?.alias || [],
    redirect: _91indicationCode_93HvI82yu0Z8Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/novo-investidor/[indicationCode].vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93Ww9zWxO4ZiMeta?.name ?? "oportunidades-operationId",
    path: _91operationId_93Ww9zWxO4ZiMeta?.path ?? "/oportunidades/:operationId()",
    meta: _91operationId_93Ww9zWxO4ZiMeta || {},
    alias: _91operationId_93Ww9zWxO4ZiMeta?.alias || [],
    redirect: _91operationId_93Ww9zWxO4ZiMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/[operationId].vue").then(m => m.default || m)
  },
  {
    name: finalizadasyscyKGy6UOMeta?.name ?? "oportunidades-finalizadas",
    path: finalizadasyscyKGy6UOMeta?.path ?? "/oportunidades/finalizadas",
    meta: finalizadasyscyKGy6UOMeta || {},
    alias: finalizadasyscyKGy6UOMeta?.alias || [],
    redirect: finalizadasyscyKGy6UOMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/finalizadas.vue").then(m => m.default || m)
  },
  {
    name: historicoBzLRAlvsBgMeta?.name ?? "oportunidades-historico",
    path: historicoBzLRAlvsBgMeta?.path ?? "/oportunidades/historico",
    meta: historicoBzLRAlvsBgMeta || {},
    alias: historicoBzLRAlvsBgMeta?.alias || [],
    redirect: historicoBzLRAlvsBgMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/historico.vue").then(m => m.default || m)
  },
  {
    name: indexkxsb0ERfIrMeta?.name ?? "oportunidades",
    path: indexkxsb0ERfIrMeta?.path ?? "/oportunidades",
    meta: indexkxsb0ERfIrMeta || {},
    alias: indexkxsb0ERfIrMeta?.alias || [],
    redirect: indexkxsb0ERfIrMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93w0bcFnFRvYMeta?.name ?? "oportunidades-parceiro-operationId",
    path: _91operationId_93w0bcFnFRvYMeta?.path ?? "/oportunidades/parceiro/:operationId()",
    meta: _91operationId_93w0bcFnFRvYMeta || {},
    alias: _91operationId_93w0bcFnFRvYMeta?.alias || [],
    redirect: _91operationId_93w0bcFnFRvYMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/oportunidades/parceiro/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexTJrkukB0UMMeta?.name ?? "originadoras",
    path: indexTJrkukB0UMMeta?.path ?? "/originadoras",
    meta: indexTJrkukB0UMMeta || {},
    alias: indexTJrkukB0UMMeta?.alias || [],
    redirect: indexTJrkukB0UMMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/originadoras/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UYPA494WoEMeta?.name ?? "painel-ajuda-slug",
    path: _91slug_93UYPA494WoEMeta?.path ?? "/painel/ajuda/:slug()",
    meta: _91slug_93UYPA494WoEMeta || {},
    alias: _91slug_93UYPA494WoEMeta?.alias || [],
    redirect: _91slug_93UYPA494WoEMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ajuda/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexaxJKh8k0h1Meta?.name ?? "painel-ajuda",
    path: indexaxJKh8k0h1Meta?.path ?? "/painel/ajuda",
    meta: indexaxJKh8k0h1Meta || {},
    alias: indexaxJKh8k0h1Meta?.alias || [],
    redirect: indexaxJKh8k0h1Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: _91operationId_93NYZ76FFScpMeta?.name ?? "painel-detalhes-investimento-operationId",
    path: _91operationId_93NYZ76FFScpMeta?.path ?? "/painel/detalhes-investimento/:operationId()",
    meta: _91operationId_93NYZ76FFScpMeta || {},
    alias: _91operationId_93NYZ76FFScpMeta?.alias || [],
    redirect: _91operationId_93NYZ76FFScpMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/detalhes-investimento/[operationId].vue").then(m => m.default || m)
  },
  {
    name: indexwG24MzITmDMeta?.name ?? "painel",
    path: indexwG24MzITmDMeta?.path ?? "/painel",
    meta: indexwG24MzITmDMeta || {},
    alias: indexwG24MzITmDMeta?.alias || [],
    redirect: indexwG24MzITmDMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/index.vue").then(m => m.default || m)
  },
  {
    name: indexPmZ8MfCIT9Meta?.name ?? "painel-indicacao",
    path: indexPmZ8MfCIT9Meta?.path ?? "/painel/indicacao",
    meta: indexPmZ8MfCIT9Meta || {},
    alias: indexPmZ8MfCIT9Meta?.alias || [],
    redirect: indexPmZ8MfCIT9Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/indicacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexHiYDF3oQcKMeta?.name ?? "painel-ir",
    path: indexHiYDF3oQcKMeta?.path ?? "/painel/ir",
    meta: indexHiYDF3oQcKMeta || {},
    alias: indexHiYDF3oQcKMeta?.alias || [],
    redirect: indexHiYDF3oQcKMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/ir/index.vue").then(m => m.default || m)
  },
  {
    name: indexvgxcta8fH7Meta?.name ?? "painel-pendentes",
    path: indexvgxcta8fH7Meta?.path ?? "/painel/pendentes",
    meta: indexvgxcta8fH7Meta || {},
    alias: indexvgxcta8fH7Meta?.alias || [],
    redirect: indexvgxcta8fH7Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/pendentes/index.vue").then(m => m.default || m)
  },
  {
    name: indexo35pKnePJFMeta?.name ?? "painel-perfil-alterar-email",
    path: indexo35pKnePJFMeta?.path ?? "/painel/perfil/alterar-email",
    meta: indexo35pKnePJFMeta || {},
    alias: indexo35pKnePJFMeta?.alias || [],
    redirect: indexo35pKnePJFMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-email/index.vue").then(m => m.default || m)
  },
  {
    name: index6d1ARlGWpTMeta?.name ?? "painel-perfil-alterar-senha",
    path: index6d1ARlGWpTMeta?.path ?? "/painel/perfil/alterar-senha",
    meta: index6d1ARlGWpTMeta || {},
    alias: index6d1ARlGWpTMeta?.alias || [],
    redirect: index6d1ARlGWpTMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-senha/index.vue").then(m => m.default || m)
  },
  {
    name: index6KR0zMHKFUMeta?.name ?? "painel-perfil-alterar-telefone",
    path: index6KR0zMHKFUMeta?.path ?? "/painel/perfil/alterar-telefone",
    meta: index6KR0zMHKFUMeta || {},
    alias: index6KR0zMHKFUMeta?.alias || [],
    redirect: index6KR0zMHKFUMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/alterar-telefone/index.vue").then(m => m.default || m)
  },
  {
    name: adicionarvuqEZTOYcpMeta?.name ?? "painel-perfil-chave-pix-adicionar",
    path: adicionarvuqEZTOYcpMeta?.path ?? "/painel/perfil/chave-pix/adicionar",
    meta: adicionarvuqEZTOYcpMeta || {},
    alias: adicionarvuqEZTOYcpMeta?.alias || [],
    redirect: adicionarvuqEZTOYcpMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/chave-pix/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexDExkQu6gKNMeta?.name ?? "painel-perfil-chave-pix",
    path: indexDExkQu6gKNMeta?.path ?? "/painel/perfil/chave-pix",
    meta: indexDExkQu6gKNMeta || {},
    alias: indexDExkQu6gKNMeta?.alias || [],
    redirect: indexDExkQu6gKNMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/chave-pix/index.vue").then(m => m.default || m)
  },
  {
    name: editary4cFNaq8BPMeta?.name ?? "painel-perfil-dados-bancarios-id-editar",
    path: editary4cFNaq8BPMeta?.path ?? "/painel/perfil/dados-bancarios/:id()/editar",
    meta: editary4cFNaq8BPMeta || {},
    alias: editary4cFNaq8BPMeta?.alias || [],
    redirect: editary4cFNaq8BPMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/[id]/editar.vue").then(m => m.default || m)
  },
  {
    name: adicionar0EzNHZx3JzMeta?.name ?? "painel-perfil-dados-bancarios-adicionar",
    path: adicionar0EzNHZx3JzMeta?.path ?? "/painel/perfil/dados-bancarios/adicionar",
    meta: adicionar0EzNHZx3JzMeta || {},
    alias: adicionar0EzNHZx3JzMeta?.alias || [],
    redirect: adicionar0EzNHZx3JzMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/adicionar.vue").then(m => m.default || m)
  },
  {
    name: indexfBQdVfRgxZMeta?.name ?? "painel-perfil-dados-bancarios",
    path: indexfBQdVfRgxZMeta?.path ?? "/painel/perfil/dados-bancarios",
    meta: indexfBQdVfRgxZMeta || {},
    alias: indexfBQdVfRgxZMeta?.alias || [],
    redirect: indexfBQdVfRgxZMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-bancarios/index.vue").then(m => m.default || m)
  },
  {
    name: indexyHrkI7syTVMeta?.name ?? "painel-perfil-dados-patrimoniais",
    path: indexyHrkI7syTVMeta?.path ?? "/painel/perfil/dados-patrimoniais",
    meta: indexyHrkI7syTVMeta || {},
    alias: indexyHrkI7syTVMeta?.alias || [],
    redirect: indexyHrkI7syTVMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-patrimoniais/index.vue").then(m => m.default || m)
  },
  {
    name: indexZgun2Ylq73Meta?.name ?? "painel-perfil-dados-pessoais",
    path: indexZgun2Ylq73Meta?.path ?? "/painel/perfil/dados-pessoais",
    meta: indexZgun2Ylq73Meta || {},
    alias: indexZgun2Ylq73Meta?.alias || [],
    redirect: indexZgun2Ylq73Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/dados-pessoais/index.vue").then(m => m.default || m)
  },
  {
    name: indexrI9qtRF54OMeta?.name ?? "painel-perfil-desativar-conta",
    path: indexrI9qtRF54OMeta?.path ?? "/painel/perfil/desativar-conta",
    meta: indexrI9qtRF54OMeta || {},
    alias: indexrI9qtRF54OMeta?.alias || [],
    redirect: indexrI9qtRF54OMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/desativar-conta/index.vue").then(m => m.default || m)
  },
  {
    name: indexNB8F5FGg3XMeta?.name ?? "painel-perfil-documentos",
    path: indexNB8F5FGg3XMeta?.path ?? "/painel/perfil/documentos",
    meta: indexNB8F5FGg3XMeta || {},
    alias: indexNB8F5FGg3XMeta?.alias || [],
    redirect: indexNB8F5FGg3XMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/documentos/index.vue").then(m => m.default || m)
  },
  {
    name: index9Jy1RCCmQbMeta?.name ?? "painel-perfil-endereco",
    path: index9Jy1RCCmQbMeta?.path ?? "/painel/perfil/endereco",
    meta: index9Jy1RCCmQbMeta || {},
    alias: index9Jy1RCCmQbMeta?.alias || [],
    redirect: index9Jy1RCCmQbMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/endereco/index.vue").then(m => m.default || m)
  },
  {
    name: indexbsNgSgvobvMeta?.name ?? "painel-perfil",
    path: indexbsNgSgvobvMeta?.path ?? "/painel/perfil",
    meta: indexbsNgSgvobvMeta || {},
    alias: indexbsNgSgvobvMeta?.alias || [],
    redirect: indexbsNgSgvobvMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: _91assetSymbol_93h7l7Ns3Bf5Meta?.name ?? "painel-wallet-assetSymbol",
    path: _91assetSymbol_93h7l7Ns3Bf5Meta?.path ?? "/painel/wallet/:assetSymbol()",
    meta: _91assetSymbol_93h7l7Ns3Bf5Meta || {},
    alias: _91assetSymbol_93h7l7Ns3Bf5Meta?.alias || [],
    redirect: _91assetSymbol_93h7l7Ns3Bf5Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/[assetSymbol].vue").then(m => m.default || m)
  },
  {
    name: depositoG66rWAGP4CMeta?.name ?? "painel-wallet-deposito",
    path: depositoG66rWAGP4CMeta?.path ?? "/painel/wallet/deposito",
    meta: depositoG66rWAGP4CMeta || {},
    alias: depositoG66rWAGP4CMeta?.alias || [],
    redirect: depositoG66rWAGP4CMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/deposito.vue").then(m => m.default || m)
  },
  {
    name: indexHEf03dAg19Meta?.name ?? "painel-wallet",
    path: indexHEf03dAg19Meta?.path ?? "/painel/wallet",
    meta: indexHEf03dAg19Meta || {},
    alias: indexHEf03dAg19Meta?.alias || [],
    redirect: indexHEf03dAg19Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: resgatehJuPsTtTnDMeta?.name ?? "painel-wallet-resgate",
    path: resgatehJuPsTtTnDMeta?.path ?? "/painel/wallet/resgate",
    meta: resgatehJuPsTtTnDMeta || {},
    alias: resgatehJuPsTtTnDMeta?.alias || [],
    redirect: resgatehJuPsTtTnDMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/painel/wallet/resgate.vue").then(m => m.default || m)
  },
  {
    name: indexwtijAw0oPSMeta?.name ?? "parceiros",
    path: indexwtijAw0oPSMeta?.path ?? "/parceiros",
    meta: indexwtijAw0oPSMeta || {},
    alias: indexwtijAw0oPSMeta?.alias || [],
    redirect: indexwtijAw0oPSMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexkhBQUNKyu2Meta?.name ?? "politica-de-privacidade",
    path: indexkhBQUNKyu2Meta?.path ?? "/politica-de-privacidade",
    meta: indexkhBQUNKyu2Meta || {},
    alias: indexkhBQUNKyu2Meta?.alias || [],
    redirect: indexkhBQUNKyu2Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/politica-de-privacidade/index.vue").then(m => m.default || m)
  },
  {
    name: indexqHRpSNiFfxMeta?.name ?? "quem-somos",
    path: indexqHRpSNiFfxMeta?.path ?? "/quem-somos",
    meta: indexqHRpSNiFfxMeta || {},
    alias: indexqHRpSNiFfxMeta?.alias || [],
    redirect: indexqHRpSNiFfxMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/quem-somos/index.vue").then(m => m.default || m)
  },
  {
    name: indexqngxVKVTYOMeta?.name ?? "remocao-de-conta",
    path: indexqngxVKVTYOMeta?.path ?? "/remocao-de-conta",
    meta: indexqngxVKVTYOMeta || {},
    alias: indexqngxVKVTYOMeta?.alias || [],
    redirect: indexqngxVKVTYOMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/remocao-de-conta/index.vue").then(m => m.default || m)
  },
  {
    name: _91signupCode_93KNbMIqXrx4Meta?.name ?? "seja-investidor-signupCode",
    path: _91signupCode_93KNbMIqXrx4Meta?.path ?? "/seja-investidor/:signupCode()",
    meta: _91signupCode_93KNbMIqXrx4Meta || {},
    alias: _91signupCode_93KNbMIqXrx4Meta?.alias || [],
    redirect: _91signupCode_93KNbMIqXrx4Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/seja-investidor/[signupCode].vue").then(m => m.default || m)
  },
  {
    name: indexrMDGhZIs5sMeta?.name ?? "termos-de-uso",
    path: indexrMDGhZIs5sMeta?.path ?? "/termos-de-uso",
    meta: indexrMDGhZIs5sMeta || {},
    alias: indexrMDGhZIs5sMeta?.alias || [],
    redirect: indexrMDGhZIs5sMeta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/termos-de-uso/index.vue").then(m => m.default || m)
  },
  {
    name: indexIwOnaoMM35Meta?.name ?? "tokenizacao",
    path: indexIwOnaoMM35Meta?.path ?? "/tokenizacao",
    meta: indexIwOnaoMM35Meta || {},
    alias: indexIwOnaoMM35Meta?.alias || [],
    redirect: indexIwOnaoMM35Meta?.redirect,
    component: () => import("/codebuild/output/src1798402820/src/hcp-web-platform/pages/tokenizacao/index.vue").then(m => m.default || m)
  }
]